import {Injectable} from "@angular/core";
import {MessageService} from "primeng/api";
import {ToasterTypeEnum} from "../../enums/toaster-type.enum";
import {IToaster} from "../../interfaces/toaster/IToaster";

@Injectable({
  providedIn: 'root'
})
export class PrimeNgToasterService implements IToaster {

  constructor(private messageService: MessageService) {
  }

  show(type: ToasterTypeEnum, title: string, message: string) {
    this.messageService.add({severity: type, summary: title || '', detail: message || ''});
  }
}
